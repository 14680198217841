<template>
  <l-map
    style="width: 100%; height: 100%; z-index:0"
    :center="map_center"
    :bounds="bounds"
    ref="gcmsmap"
    @update:bounds="boundsUpdated"
    :options="{attributionControl: false}"
  >
    <l-control-layers position="topleft"></l-control-layers>
    <l-tile-layer v-for="tileProvider in tileProviders"
      :key="tileProvider.name"
      :url="tileProvider.url"
      :name="tileProvider.name"
      :attribution="tileProvider.attribution"
      :visible="tileProvider.visible"
      layer-type="base"
    >
    </l-tile-layer>
    <l-marker :lat-lng="marker_position" >
      <l-icon :icon-size="[64,64]" :icon-anchor="[32,64]">
        <map-marker-icon class="icon-big" />
      </l-icon>
    </l-marker>
    <l-marker :lat-lng="position" v-if="position != null">
        <l-icon :icon-size="[24,24]" :icon-anchor="[13,14]">
          <crosshairs-gps-icon class="icon-big icon-position" />
        </l-icon>
    </l-marker>
    <l-polyline
      :lat-lngs="bounds"
      v-if="position != null && view == 'bounds'"
      color="#00B3FD">
    </l-polyline>
    <l-control position="topright">
        <button
          @click="clickPositionButton"
          class="icon-button"
          :class="{ 'locate-icon-active': locating }"
        >
          <crosshairs-gps-icon />
        </button>
        <button
          @click="clickMarkerButton"
          class="icon-button"
        >
          <map-marker-icon />
        </button>
        <button
          v-if="position != null"
          @click="clickBoundsButton"
          class="icon-button"
        >
          <map-marker-distance-icon /> <template v-if="distance != null"> {{ distance }} km</template>
        </button>
        <button
          @click="clickRouteButton"
          class="icon-button"
        >
          <car-icon />
        </button>
        <button
          @click="clickGoogleButton"
          class="icon-button"
        >
          <google-maps-icon />
        </button>
    </l-control>
    <l-control-attribution position="bottomright" prefix="Stadtausstellung"></l-control-attribution>
  </l-map>
</template>


<script>

import { L, LMap, LTileLayer, LMarker, LIcon, LControl, LPolyline, LControlLayers, LControlAttribution } from 'vue2-leaflet';
import VueGeolocation from 'vue-browser-geolocation';
import Vue from 'vue';
import {DEVICE_PIXEL_RATIO} from 'ol/has.js';

import CrosshairsGpsIcon from "vue-material-design-icons/CrosshairsGps.vue";
import MapMarkerDistanceIcon from "vue-material-design-icons/MapMarkerDistance.vue";
import MapMarkerIcon from "vue-material-design-icons/MapMarker.vue";
import CarIcon from "vue-material-design-icons/Car.vue";
import GoogleMapsIcon from "vue-material-design-icons/GoogleMaps.vue";

import * as turf from 'turf';

import 'leaflet/dist/leaflet.css';
import "vue-material-design-icons/styles.css";

Vue.use(VueGeolocation);

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  name: "OsmMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPolyline,
    LControl,
    LControlLayers,
    CrosshairsGpsIcon,
    MapMarkerDistanceIcon,
    MapMarkerIcon,
    CarIcon,
    GoogleMapsIcon,
    LControlAttribution,
  },
  props: {
    lat: Number,
    lng: Number,
  },
  data(){
    return {
      tileProviders: {},
      position: null,
      view: "marker",
      locating: false,
      got_bounds: null,
      set_bounds_distance: 1.5,
    }
  },
  beforeMount(){
    var basemap = {
      name: "Basemap",
      attribution: "Datenquelle: <a href='https://basemap.at'>basemap.at</a>",
      url: "https://maps.wien.gv.at/basemap/geolandbasemap/normal/google3857/{z}/{y}/{x}.png",
      visible: false
    }

    var basemap_ortho = {
      name: "Basemap Orthofoto",
      attribution: "Datenquelle: <a href='https://basemap.at'>basemap.at</a>",
      url: "https://maps.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpeg",
      visible: false
    }

    var staio = {
      name: "Stadtausstellung",
      attribution: "© <a href='https://www.openstreetmap.org'>OpenStreetMap</a> contributors",
      url: "https://tiles.sta.io/osm_tiles/{z}/{x}/{y}.png",
      visible: false
    }

    var staio_bright = {
      name: "Stadtausstellung (Bright)",
      attribution: "© <a href='https://www.openstreetmap.org'>OpenStreetMap</a> contributors",
      url: "https://tiles.sta.io/bright/{z}/{x}/{y}.png",
      visible: true
    }

    if (DEVICE_PIXEL_RATIO > 1){
      basemap["url"] = "https://maps.wien.gv.at/basemap/bmaphidpi/normal/google3857/{z}/{y}/{x}.jpeg";
      staio["url"] = "https://tiles.sta.io/retina/{z}/{x}/{y}.png";
      staio_bright["url"] = "https://tiles.sta.io/bright_retina/{z}/{x}/{y}.png";
    }

    this.tileProviders = [
      staio_bright,
      staio,
      basemap,
      basemap_ortho
    ];
  },
  mounted(){
    this.$refs.gcmsmap.mapObject._onResize();

    if (navigator.permissions){
      navigator.permissions.query({
        name: 'geolocation'
      }).then(permission => {
        if (permission.state === "granted"){
          this.watchLocation(false);
        }
      });
    }
  },
  methods: {
    boundsUpdated (bounds) {
      this.got_bounds = bounds;
    },
    watchLocation(set_view){
      var vm = this;
      this.$watchLocation({enableHighAccuracy: true})
        .then(coordinates => {
          if (vm.position == null){
            vm.position = [coordinates.lat, coordinates.lng];
            if (set_view){
              vm.view = "bounds";
            }
            vm.locating = false;
          } else {
            vm.position = [coordinates.lat, coordinates.lng];
          }
        })
        .catch(() => {
          vm.locating = false;
        });
    },
    clickGoogleButton:function(){
      this.google_button_active = true;
      window.open(this.google_maps_url);
    },
    clickRouteButton:function(){
      this.route_button_active = true;
      window.open(this.google_maps_route_url);
    },
    clickPositionButton:function(){
      if (this.position == null){
        if (!this.locating){
          this.locating = true;
          this.watchLocation(true);
        }
      } else {
        if (this.view == "position"){
          this.set_bounds_distance = this.bounds_distance;
        } else {
          this.view = "position";
        }
      }
    },
    clickMarkerButton:function(){
      if (this.view == "marker"){
        this.set_bounds_distance = this.bounds_distance;
      } else {
        this.view = "marker";
      }
    },
    clickBoundsButton:function(){
        this.view = "bounds";
    },
    boundsFromPoint:function(point, distance=1.5){
      if (distance < 0){
        distance = 0;
      }
      var p = turf.point(point);
      var top = turf.destination(p, distance, 0);
      var right = turf.destination(p, distance, 90);
      var bottom = turf.destination(p, distance, 180);
      var left = turf.destination(p, distance, 270);
      return [top.geometry.coordinates, right.geometry.coordinates, bottom.geometry.coordinates, left.geometry.coordinates];
    }
  },
  computed: {
    distance:function(){
      if (this.position != null){
        var to = turf.point(this.marker_position);
        var from = turf.point(this.position);
        var distance = turf.distance(from, to);
        return distance.toFixed(2);
      } else {
        return null;
      }
    },
    google_maps_url:function(){
      return "https://www.google.com/maps/search/?api=1&query="+this.lat+","+this.lng;
    },
    google_maps_route_url:function(){
      return "https://www.google.com/maps/dir/?api=1&destination="+this.lat+","+this.lng;
    },
    marker_position:function(){
      return [this.lat, this.lng];
    },
    map_center:function(){
      if (this.position != null && this.view == "position"){
        return this.position;
      } else if (this.view == "marker"){
        return this.marker_position;
      } else if (this.position != null && this.view == "bounds"){
        var features = turf.featureCollection([
          turf.point(this.position),
          turf.point(this.marker_position),
        ]);
        var center_point = turf.center(features);
        return center_point.geometry.coordinates;
      } else {
        return this.marker_position;
      }
    },
    bounds:function(){
      if (this.view == "position" && this.position != null){
        return this.boundsFromPoint(this.position, this.set_bounds_distance);
      } else if (this.view == "bounds" && this.position != null){
        return [this.marker_position, this.position]
      } else {
        return this.boundsFromPoint(this.marker_position, this.set_bounds_distance);
      }
    },
    bounds_distance(){
      if (this.got_bounds == null){
        return this.set_bounds_distance/2;
      }

      var from = turf.point([this.got_bounds._northEast.lat, this.got_bounds._northEast.lng]);
      var to = turf.point([this.got_bounds._northEast.lat, this.got_bounds._southWest.lng]);
      var distance1 = turf.distance(from, to);

      from = turf.point([this.got_bounds._northEast.lat, this.got_bounds._northEast.lng]);
      to = turf.point([this.got_bounds._southWest.lat, this.got_bounds._northEast.lng]);
      var distance2 = turf.distance(from, to);

      var distance = Math.min(distance1, distance2);

      return distance/4;
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">

@import '../assets/infinite.css';

span {
  padding:0px;
  margin:0px;
}

.icon-button {
  color:#000000;
  font-size: 20px;
  background: rgba(240, 240, 240, 0.6);
  margin: 5px;
  padding: 7px;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s ease;


  &:hover {
    background: rgba(255, 255, 255, 1);
  }

  &.locate-icon-active {
    > span {
      animation: pulsate 2s ease-out;
      animation-iteration-count: infinite;
    }
  }
}
</style>


<style lang="scss">
.material-design-icon.icon-big {
  width: 64px;
  height: 64px;

  &.icon-position {
    width: 24px;
    height: 24px;

    > .material-design-icon__svg {
      width: 24px;
      height: 24px;
      color: #4285F4;
      filter: drop-shadow( 2px 2px 2px rgba(255,255,255, .5));
    }
  }

  > .material-design-icon__svg {
    width: 64px;
    height: 64px;
    color: #EA4335;
    filter: drop-shadow( 2px 2px 2px rgba(0, 0, 0, .5));
  }
}
</style>
