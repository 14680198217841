<template>
  <l-map
    style="width: 100%; height: 100%"
    ref="gcmsmap"
    :center="map_center"
    :bounds="map_bounds"
    :options="{attributionControl: false}"
  >
    <l-control-layers position="topleft"></l-control-layers>
    <l-tile-layer v-for="tileProvider in tileProviders"
      :key="tileProvider.name"
      :url="tileProvider.url"
      :name="tileProvider.name"
      :attribution="tileProvider.attribution"
      :visible="tileProvider.visible"
      layer-type="base"
    >
    </l-tile-layer>

    <l-marker :lat-lng="[marker.lat, marker.lng]" v-for="marker in markers" :key="marker.name">
      <l-icon :icon-size="[64,64]" :icon-anchor="[32,64]">
        <map-marker-icon class="icon-big" />
      </l-icon>
      <l-popup :content="marker.name"></l-popup>
      <l-tooltip :content="marker.name"></l-tooltip>
    </l-marker>

    <l-marker :lat-lng="position" v-if="position != null">
        <l-icon :icon-size="[24,24]" :icon-anchor="[13,14]">
          <crosshairs-gps-icon class="icon-big icon-position" />
        </l-icon>
    </l-marker>

    <l-control position="topright">
        <button
          @click="clickPositionButton"
          class="icon-button"
          :class="{ 'locate-icon-active': locating }"
        >
          <crosshairs-gps-icon />
        </button>
    </l-control>
    <l-control-attribution position="bottomright" prefix="Stadtausstellung" ></l-control-attribution>
  </l-map>
</template>


<script>

import { L, LMap, LTileLayer, LMarker, LIcon, LControl, LControlLayers, LControlAttribution, LPopup, LTooltip } from 'vue2-leaflet';
import VueGeolocation from 'vue-browser-geolocation';
import Vue from 'vue';
import axios from 'axios';
import {DEVICE_PIXEL_RATIO} from 'ol/has.js';

import CrosshairsGpsIcon from "vue-material-design-icons/CrosshairsGps.vue";
import MapMarkerIcon from "vue-material-design-icons/MapMarker.vue";

import * as turf from 'turf';

import 'leaflet/dist/leaflet.css';
import "vue-material-design-icons/styles.css";

Vue.use(VueGeolocation);

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  name: "MarkerListMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControl,
    LControlLayers,
    CrosshairsGpsIcon,
    MapMarkerIcon,
    LControlAttribution,
    LPopup,
    LTooltip,
  },
  props: {
    marker_list_url: {
      type: String,
      default: null,
    },
    marker_list: {
      type: Array,
      default: null
    },
  },
  data(){
    return {
      tileProviders: {},
      position: null,
      locating: false,
      markers: null,
    }
  },
  beforeMount(){
    var basemap = {
      name: "Basemap",
      attribution: "Datenquelle: <a href='https://basemap.at'>basemap.at</a>",
      url: "https://maps.wien.gv.at/basemap/geolandbasemap/normal/google3857/{z}/{y}/{x}.png",
      visible: false
    }

    var basemap_ortho = {
      name: "Basemap Orthofoto",
      attribution: "Datenquelle: <a href='https://basemap.at'>basemap.at</a>",
      url: "https://maps.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpeg",
      visible: false
    }

    var staio = {
      name: "Stadtausstellung",
      attribution: "© <a href='https://www.openstreetmap.org'>OpenStreetMap</a> contributors",
      url: "https://tiles.sta.io/osm_tiles/{z}/{x}/{y}.png",
      visible: false
    }

    var staio_bright = {
      name: "Stadtausstellung (Bright)",
      attribution: "© <a href='https://www.openstreetmap.org'>OpenStreetMap</a> contributors",
      url: "https://tiles.sta.io/bright/{z}/{x}/{y}.png",
      visible: true
    }

    if (DEVICE_PIXEL_RATIO > 1){
      basemap["url"] = "https://maps.wien.gv.at/basemap/bmaphidpi/normal/google3857/{z}/{y}/{x}.jpeg";
      staio["url"] = "https://tiles.sta.io/retina/{z}/{x}/{y}.png";
      staio_bright["url"] = "https://tiles.sta.io/bright_retina/{z}/{x}/{y}.png";
    }

    this.tileProviders = [
      staio_bright,
      staio,
      basemap,
      basemap_ortho
    ];

    if (this.marker_list_url != null){
      this.load_markers_from_url();
    }

  },
  mounted(){
    this.$refs.gcmsmap.mapObject._onResize();

    if (navigator.permissions){
      navigator.permissions.query({
        name: 'geolocation'
      }).then(permission => {
        if (permission.state === "granted"){
          this.watchLocation(false);
        }
      });
    }
  },
  methods: {
    load_markers_from_url(){
      var vm = this;
      axios.get(this.marker_list_url)
        .then((response)=>{
          vm.markers = response.data;
        });
    },
    watchLocation(){
      var vm = this;
      this.$watchLocation({enableHighAccuracy: true})
        .then(coordinates => {
          if (vm.position == null){
            vm.position = [coordinates.lat, coordinates.lng];
            vm.locating = false;
          } else {
            vm.position = [coordinates.lat, coordinates.lng];
          }
        })
        .catch(() => {
          vm.locating = false;
        });
    },
    clickPositionButton:function(){
      if (this.position == null && !this.locating){
        this.locating = true;
        this.watchLocation(true);
      }
    },
    getFeatureCollection(){
      if (this.markers != null){
        var points = new Array();
        for (var p of this.markers){
          points.push(turf.point([p.lat, p.lng], {name: p.name}));
        }
        if (this.position != null){
          points.push(turf.point(this.position));
        }
        return turf.featureCollection(points);
      } else {
        return null;
      }
    }
  },
  computed: {
    map_center(){
      var fc = this.getFeatureCollection();
      if (fc != null){
        return turf.center(fc).geometry.coordinates;
      } else if (this.position != null){
        return this.position;
      } else {
        return [0,0]
      }
    },
    map_bounds(){
      var fc = this.getFeatureCollection();
      var bbox;
      if (fc != null){
        bbox = turf.bbox(fc);
        return [{"lat": bbox[0], "lng": bbox[1]}, {"lat": bbox[2], "lng": bbox[3]}];
      } else {
        bbox = [0, 0, 0, 0];
        return [{"lat": bbox[0], "lng": bbox[1]}, {"lat": bbox[2], "lng": bbox[3]}];
      }
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">

@import '../assets/infinite.css';

.icon-button {
  color:#000000;
  font-size: 20px;
  background: rgba(240, 240, 240, 0.6);
  margin: 5px;
  padding: 7px;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s ease;


  &:hover {
    background: rgba(255, 255, 255, 1);
  }

  &.locate-icon-active {
    > span {
      animation: pulsate 2s ease-out;
      animation-iteration-count: infinite;
    }
  }
}
</style>


<style lang="scss">
.material-design-icon.icon-big {
  width: 64px;
  height: 64px;

  &.icon-position {
    width: 24px;
    height: 24px;

    > .material-design-icon__svg {
      width: 24px;
      height: 24px;
      color: #4285F4;
      filter: drop-shadow( 2px 2px 2px rgba(255,255,255, .5));
    }
  }

  > .material-design-icon__svg {
    width: 64px;
    height: 64px;
    color: #EA4335;
    filter: drop-shadow( 2px 2px 2px rgba(0, 0, 0, .5));
  }
}
</style>
